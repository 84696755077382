<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Authentication</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Update your password.</span>
      </div>
    </div>

    <div class="card-body">
      <!-- begin::Form -->
      <form class="form">
        <password-change-form
          ref="passwordChangeForm"

          :document="document"

          action="EDIT"
          :disabled="isLoadingForm"
        />

        <div class="d-flex justify-content-between border-top pt-10">
          <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4 ml-auto" @click.prevent="submitForm">
            Save
          </button>
        </div>
      </form>
      <!-- end::Form -->
    </div>
  </div>
</template>

<script>
import { validations } from '@vedicium/core-vue';

import User from '@/libs/classes/user';

import passwordChangeForm from './components/password-change.form.vue';

export default {
  props: {
    resource: {
      type: User,
      default: null,
    },
  },
  components: {
    passwordChangeForm,
  },
  data () {
    return {
      isLoadingForm: false,

      document: (this.resource ? this.resource.clone() : null),
    };
  },

  methods: {
    async validate () {
      const validationArray = [this.$refs.passwordChangeForm.$v];

      await validations.validateArray(validationArray);
    },
    async submitForm () {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();
        await this.$ws.put(`${User.uri}/password/change`, {
          body: {
            current_password: this.$refs.passwordChangeForm.form.current_password,
            password: this.$refs.passwordChangeForm.form.password,
          },
        });
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.$refs.passwordChangeForm.resetForm();
    },
  },
};
</script>
