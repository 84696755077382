<template>
  <div class="row">
    <div class="col-12 form-group border-bottom pb-5">
      <label>Current password <sup>Required</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'key']" />
            </i>
          </span>
        </div>
        <input
          type="password"
          :class="['form-control form-control-lg', { 'is-invalid': $v.form.current_password.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.form.current_password.$model"
        >
      </div>
    </div>

    <div class="col-12 col-lg-6 form-group">
      <label>New password <sup>Required</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'key']" />
            </i>
          </span>
        </div>
        <input
          type="password"
          :class="['form-control form-control-lg', { 'is-invalid': $v.form.password.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.form.password.$model"
        >
      </div>
    </div>

    <div class="col-12 col-lg-6 form-group">
      <label>Confirm password <sup>Required</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fas', 'key']" />
            </i>
          </span>
        </div>
        <input
          type="password"
          :class="['form-control form-control-lg', { 'is-invalid': $v.form.password_confirm.$error }]"
          :disabled="isDisabled"
          :readonly="isReadOnly"

          v-model.trim="$v.form.password_confirm.$model"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import Form from '@/mixins/form';
import User from '@/libs/classes/user';

export default {
  mixins: [Form],
  props: {
    document: {
      type: User,
      default: null,
    },
  },

  data () {
    return {
      form: {
        current_password: null,

        password: null,
        password_confirm: null,
      },
    };
  },

  validations: {
    form: {
      current_password: {
        required,
      },
      password: {
        // TODO: Add password requirements
        required,
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  methods: {
    resetForm () {
      this.$set(this.form, 'current_password', null);
      this.$set(this.form, 'password', null);
      this.$set(this.form, 'password_confirm', null);

      this.$v.$reset();
    },
  },
};
</script>
